
import NavBar from './components/NavBar.vue';
import { useStore, UserData } from './store';
import { readUserDataById, writeUserData } from './services/FirebaseService';
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'App',
	setup() {
		return { store: useStore() };
	},
	data() {
		return {
			testMode: false
		};
	},
	components: {
		NavBar
	},
	methods: {
		wipeStore(): void {
			if (this.testMode) {
				this.store.setUserData(new UserData());
				this.store.setConvenienceData(new UserData());
			}
		},
		// TODO - this method feels out of place. Put it with the other comp that uses firestore?
		async addUserDataToStore(): Promise<void> {
			const uid = this.store.getState().user.uid;
			const userData = await readUserDataById(uid);
			if (userData.days && userData.days.length > 0) {
				this.store.setUserData(userData);
				this.store.setConvenienceData(
					this.store.parseConvenienceData(userData)
				);
			} else {
				this.handleNewUser();
			}
		},

		async write(): Promise<void> {
			if (this.testMode) {
				const uid = this.store.getState().user.uid;
				const userData = this.store.getState().userData;
				await writeUserData(uid, userData);
			}
		},

		handleNewUser(): void {
			this.$router.push('/register');
		}
	}
});
