
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'Set',
	props: {
		exerciseName: { type: String, default: '' },
		setNum: { type: Number, default: -1 },
		promptReps: { type: Number, default: -1 }
	},
	emits: ['set-done', 'set-undone'],
	data() {
		return {
			finished: false,
			doneReps: this.promptReps
		};
	},
	methods: {
		emitRepChange(): void {
			this.$emit('set-done', this.doneReps, this.setNum);
		},
		toggleDone(): void {
			this.finished = !this.finished;
			if (this.finished) this.emitRepChange();
			else this.$emit('set-undone', this.setNum);
		},
		repUp(): void {
			this.doneReps++;
			if (this.finished) this.emitRepChange();
		},
		repDown(): void {
			if (this.doneReps > 0) this.doneReps--;
			if (this.finished) this.emitRepChange();
		}
	}
});
