
import { useStore, Day } from '../store';
import { beautifyStr } from '../utils/StringUtils';
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'History',
	setup() {
		return { store: useStore() };
	},
	data() {
		return {
			uiHist: Array<Day>(),
			beautifyStr: beautifyStr
		};
	},
	beforeMount() {
		this.buildUIHist();
	},
	methods: {
		buildUIHist(): void {
			var userData = this.store.getState().userData;
			var pattern = new RegExp('^[0-9]{4}-[0-9]{2}-[0-9]{2}');

			for (const session of userData.days) {
				const { date, exercise, sets } = session;
				if (pattern.test(date)) {
					const dayToAdd = new Day(date, beautifyStr(exercise), sets);
					this.uiHist.unshift(dayToAdd);
				}
			}
		}
	}
});
