
import Auth from './Auth.vue';
import { useStore, UserData } from '../store';
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'NavBar',
	components: { Auth },
	emits: ['new-user'],
	setup() {
		return { store: useStore() };
	},
	computed: {
		showHome(): boolean {
			return (
				this.currentRouteName() !== 'Home' &&
				this.currentRouteName() !== 'Root' &&
				this.currentRouteName() !== 'Register'
			);
		},
		showHistory(): boolean {
			const userData = new UserData(this.store.getState().userData.days);
			return (
				userData.days.length !== 0 &&
				this.currentRouteName() !== 'History' &&
				this.currentRouteName() !== 'Register'
			);
		},
		showAuth(): boolean {
			return this.currentRouteName() !== 'Register';
		},
		titleName(): string {
			const currentState = this.store.getState();
			return currentState.user.name ? currentState.user.name + "'s" : 'Easy';
		}
	},
	methods: {
		currentRouteName(): string | symbol {
			if (this.$route.name) {
				return this.$route.name;
			} else {
				return '';
			}
		},
		newUser(): void {
			this.$emit('new-user');
		}
	}
});
