
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'HowTo',
	methods: {
		takeHome(): void {
			this.$router.push('/home');
		}
	}
});
