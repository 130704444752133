
import { useStore, Day } from '../store';
import { writeUserData } from '../services/FirebaseService';
import { urlifyStr } from '../utils/StringUtils';
import { prepMsg } from '../utils/EMessages';
import HowTo from '../components/HowTo.vue';
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'Register',
	setup() {
		return { store: useStore() };
	},
	components: {
		HowTo
	},
	data() {
		return {
			name: '',
			repsTuple: Array<string>(),
			daysList: [new Day(), new Day(), new Day()],
			today: new Date().toJSON().slice(0, 10),
			error: '',
			errNmTup: [0, 0, 0],
			errRpTup: [0, 0, 0],
			regDone: false
		};
	},
	beforeMount() {
		this.name = this.store.getState().user.name;
	},
	methods: {
		validateAndWrite(): void {
			try {
				this.validate(false);
				this.write();
				this.regDone = true;
			} catch (e) {
				this.error = e;
			}
		},
		validateRow(type: string, idx: number) {
			if ('NAME' == type) {
				const namePattern = new RegExp(/^\w[\w\s]*$/);
				this.errNmTup[idx] = 0;
				if (
					!this.daysList[idx].exercise ||
					!namePattern.test(this.daysList[idx].exercise)
				) {
					this.errNmTup[idx] = 1;
				}
			}

			if ('REP' == type) {
				const numPattern = new RegExp(/^\d+$/);
				this.errRpTup[idx] = 0;
				if (!this.repsTuple[idx] || !numPattern.test(this.repsTuple[idx])) {
					this.errRpTup[idx] = 1;
				}
			}
		},
		validate(silent: boolean): void {
			this.errNmTup = [0, 0, 0];
			this.errRpTup = [0, 0, 0];
			const namePattern = new RegExp(/^\w[\w\s]*$/);
			const numPattern = new RegExp(/^\d+$/);
			const nameSet = new Set<string>();
			let errSet = new Set<string>();

			for (let idx in this.daysList) {
				let nextDay = this.daysList[idx];
				nextDay.date = idx;

				if (!nextDay.exercise || !namePattern.test(nextDay.exercise)) {
					let errStr = 'BAD_NAME:';
					errStr = prepMsg(errStr);
					errSet.add(errStr);
					this.errNmTup[idx] = 1;
				}

				if (nameSet.has(nextDay.exercise)) {
					let errStr = 'DUP_NAME:';
					errStr = prepMsg(errStr);
					errSet.add(errStr);
					this.errNmTup[idx] = 1;
				} else nameSet.add(nextDay.exercise);

				if (!this.repsTuple[idx] || !numPattern.test(this.repsTuple[idx])) {
					let errStr = 'BAD_REP:';
					errStr = prepMsg(errStr);
					errSet.add(errStr);
					this.errRpTup[idx] = 1;
				} else nextDay.sets[0] = Number.parseInt(this.repsTuple[idx]);
			}
			if (errSet.size > 0 && !silent) throw errSet;
		},
		write(): void {
			let currentData = this.store.getState().userData;
			const uid = this.store.getState().user.uid;

			if (!currentData || !uid) {
				throw 'BAD_STATE';
			}

			if (currentData.days.length !== 0) {
				throw 'DUP_USER';
			}

			for (let nextDay of this.daysList) {
				nextDay.exercise = urlifyStr(nextDay.exercise);
			}

			currentData.days = this.daysList;
			this.store.setUserData(currentData);
			this.store.setConvenienceData(
				this.store.parseConvenienceData(currentData)
			);
			writeUserData(uid, currentData);
		}
	}
});
